import { server } from "./api";

async function getAlimentos() {
  try {
    const { data } = await server.get("/alimentos");

    const options = data.results.map((product: any) => {
      return {
        label: product.nome,
        id: product.id,
      };
    });

    return data && data.results ? data.results : [];
  } catch (error: any) {
    const erro = error.response.data.error;
    throw new Error(erro)
  }
}



export { getAlimentos };