import { useContext, useState, useEffect } from 'react';
import { Formik, Form, FormikHelpers, useFormikContext } from 'formik';
import { Link, useParams } from 'react-router-dom';

import { initialValues } from './initialValues';

import DadosBancariosForm from '../../../components/DadosBancariosForm'
import EnderecoForm from '../../../components/EnderecoForm'
import SenhaForm from '../../../components/SenhaForm'
import InfosPessoaisAgricultor from './infosPessoaisAgricultor';
import AssociadosForm from './AssociadosForm'

import {
  Agricultor,
  AgricultorCadastroFormValues,
  Associados,
  AssociadosFormValues,
} from '../../../assets/interfaces/Agricultor';

import { AgricultorContext, editarAgricultor } from '../../../contexts/AgricultorContext';

import styles from '../../../common/Cadastro.module.scss';


export default function AgricultorForm() {

  const { 
    cadastrarAgricultor, 
    buscarAgricultorPorId, 
    alterarAgricultor,
  } = useContext(AgricultorContext);

  const [agricultor, setAgricultor] = useState<Agricultor | undefined>();
  const [isEditingAgricultor, setIsEditingAgricultor] = useState(false);
  const [associados, setAssociados] = useState<Associados[]>([]);
  const [seedForm, setSeedForm] = useState<AgricultorCadastroFormValues>({...initialValues});

  const { slug }: any = useParams();
  const { values, setFieldValue } = useFormikContext<AgricultorCadastroFormValues>();

  function unnormlize() {

    if(agricultor){
      if (agricultor.tipo === 1){

        setFieldValue("tipo", agricultor.tipo);
        setFieldValue("nome_proponente", agricultor.nome_proponente);
        setFieldValue("email", agricultor.email);
        setFieldValue("cpf", agricultor.cpf);
        setFieldValue("dap", agricultor.dap);
        setFieldValue("celular", agricultor.celular);
        setFieldValue("telefone", agricultor.telefone);
        setFieldValue("cep", agricultor.cep);
        setFieldValue("logradouro", agricultor.logradouro);
        setFieldValue("complemento", agricultor.complemento);
        setFieldValue("bairro", agricultor.bairro);
        setFieldValue("cidade", agricultor.cidade);
        setFieldValue("estado", agricultor.estado);
        setFieldValue("area_produzida", agricultor.area_produzida);
        setFieldValue("banco", agricultor.banco);
        setFieldValue("agencia", agricultor.agencia);
        setFieldValue("conta_bancaria", agricultor.conta_bancaria);
        setFieldValue("operacao", agricultor.operacao);
        setFieldValue("ic_priorizado", agricultor.ic_priorizado);

      }else if(agricultor.tipo === 2){

        setFieldValue("tipo", agricultor.tipo);
        setFieldValue("nome_proponente", agricultor.nome_proponente);
        setFieldValue("email", agricultor.email);
        setFieldValue("cnpj", agricultor.cnpj);
        setFieldValue("dap", agricultor.dap);
        setFieldValue("celular", agricultor.celular);
        setFieldValue("telefone", agricultor.telefone);
        setFieldValue("representante_legal", agricultor.representante_legal);
        setFieldValue("representante_cpf", agricultor.representante_cpf);
        setFieldValue("numero_associados", agricultor.numero_associados);
        setFieldValue("ic_priorizado", agricultor.ic_priorizado);
        setFieldValue("cep", agricultor.cep);
        setFieldValue("logradouro", agricultor.logradouro);
        setFieldValue("complemento", agricultor.complemento);
        setFieldValue("bairro", agricultor.bairro);
        setFieldValue("cidade", agricultor.cidade);
        setFieldValue("estado", agricultor.estado);
        setFieldValue("area_produzida", agricultor.area_produzida);
        setFieldValue("banco", agricultor.banco);
        setFieldValue("agencia", agricultor.agencia);
        setFieldValue("conta_bancaria", agricultor.conta_bancaria);
        setFieldValue("operacao", agricultor.operacao);
        setFieldValue("associados_cpf", agricultor.associados_cpf);

      }

      if (agricultor?.associados && Array.isArray(agricultor?.associados) && agricultor?.associados.length) {
        // inicializa form associados        
        setFieldValue("associados", [...agricultor?.associados]);
      }
      
    }
  }

  useEffect(() => {

    const getAgricultor = async () => {
      const responseAgricultor = await buscarAgricultorPorId(slug);
      setAgricultor(responseAgricultor);
    };

    if (slug) {
      getAgricultor();
      setIsEditingAgricultor(true);
    }

  }, [slug]);


  useEffect(() => {
    unnormlize();
  },[agricultor])

  return (
    <>

        <Form>
            <section>
              <InfosPessoaisAgricultor isEdditing={isEditingAgricultor}/>
            </section>

            { values && values?.tipo && Number(values.tipo) != 1 ? (
                <section>
                  <AssociadosForm isEdditing={isEditingAgricultor}/>
                </section>
              ) : null
            }

            <section>
              <EnderecoForm isEdditing={isEditingAgricultor}/>
            </section>

{/*         
            REMOVE DADOS BANCARIOS TEMPORARIAMENTE
            <section>
              <DadosBancariosForm isEdditing={isEditingAgricultor}/>
            </section>

*/}
            <section>
              <SenhaForm isEdditing={isEditingAgricultor}/>
            </section>

            <section>
              <div className={styles.formGroup}>
                <span></span>
                <button type='submit'>
                  {isEditingAgricultor 
                    ? 'Salvar'
                    : 'Cadastrar'}
                </button>
              </div>
            </section>
        </Form>

      <br />
    </>
  );
}
