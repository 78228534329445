import { FocusEvent, useContext, useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { viacep } from '../../services/api';

import { Error } from '../Error';
import { Input } from '../Input';

import styles from '../../common/Cadastro.module.scss';


interface DadosBancariosValues {
  banco: string;
  agencia: string;
  conta_bancaria: string;
  operacao: string;
}

interface EnderecoValues {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  area_produzida: number;
}

export default function EnderecoForm({isEdditing} : {isEdditing: boolean}) {
  const { values, errors, touched, handleChange, setFieldValue } = useFormikContext<EnderecoValues>();

  async function handleCEP(
    event: FocusEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) {
    const { value } = event.target;

    const cep = value.replace(/[^0-9]/g, '');

    if (cep.length !== 8) {
      return;
    }

    const { data } = await viacep.get(`${cep}/json`);

    setFieldValue('cidade', data.localidade);
    setFieldValue('logradouro', data.logradouro);
    setFieldValue('estado', data.uf);
    setFieldValue('bairro', data.bairro);
  }

  return (
    <>
                     
      <h3>Endereço</h3>

      <div className={styles.formGroup}>
        <Input
          isRequired
          label='CEP'
          mask='99999-999'
          name='cep'
          onBlur={(event) => {
            handleCEP(event, setFieldValue);
          }}
          onChange={handleChange}
          type='text'
          placeholder={values.cep}
        >
          {errors.cep && touched.cep ? (
            <Error text={errors.cep} />
          ) : null}
        </Input>

        <Input
          label='Logradouro'
          name='logradouro'
          onChange={handleChange}
          type='text'
          placeholder={values.logradouro}
          helperText='Insira o nome da Rua, Avenida...'
        >
          {errors.logradouro && touched.logradouro ? (
            <Error text={errors.logradouro} />
          ) : null}
        </Input>

        <Input
          label='Complemento'
          name='complemento'
          onChange={handleChange}
          type='text'
          placeholder={values.complemento}
        >
          {errors.complemento && touched.complemento ? (
            <Error text={errors.complemento} />
          ) : null}
        </Input>

        <Input
          label='Bairro'
          name='bairro'
          onChange={handleChange}
          type='text'
          placeholder={values.bairro}
        >
          {errors.bairro && touched.bairro ? (
            <Error text={errors.bairro} />
          ) : null}
        </Input>

        <Input
          isRequired
          label='Cidade'
          name='cidade'
          onChange={handleChange}
          disabled={true}
          type='text'
          placeholder={values.cidade}
        >
          {errors.cidade && touched.cidade ? (
            <Error text={errors.cidade} />
          ) : null}
        </Input>

        <Input
          isRequired
          label='Estado'
          mask='aa'
          name='estado'
          onChange={handleChange}
          type='text'
          disabled={true}
          placeholder={values.estado}
        >
          {errors.estado && touched.estado ? (
            <Error text={errors.estado} />
          ) : null}
        </Input>
{/*
        <Input
          isRequired
          label='Area Produzida (m²)'
          name='area_produzida'
          onChange={handleChange}
          type='number'
          placeholder='Insira o tamanho da área produzida em m²'
        >
          {errors.area_produzida && touched.area_produzida ? (
            <Error text={errors.area_produzida} />
          ) : null}
        </Input>
*/}

      </div>
                   
    </>
  );
}
