import * as Yup from "yup";

import { AgricultorCadastroFormValues } from "../../../assets/interfaces/Agricultor";

export const validationSchema: Yup.SchemaOf<AgricultorCadastroFormValues> =
  Yup.object().shape({
    tipo: Yup.number()
      .required("Este campo é de preenchimento obrigatório.")
      .integer("Campo inválido.")
      .positive("Campo inválido.")
      .min(1, "Campo inválido.")
      .max(2, "Campo inválido."),
    email: Yup.string()
      .email("Email inválido.")
      .required("O campo email é de preenchimento obrigatório."),
    nome_proponente: Yup.string().required("O campo nome é de preenchimento obrigatório."),
    cpf: Yup.string()
      .matches(/^\d{3}.\d{3}.\d{3}-\d{2}$/, "CPF inválido.")
      .when("tipo", {
        is: 1,
        then: Yup.string().required(
          "O campo CPF é de preenchimento obrigatório."
        ),
      }),
    cnpj: Yup.string()
      .matches(/^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/, "CNPJ inválido.")
      .when("tipo", {
        is: 2,
        then: Yup.string().required(
          "O campo CNPJ é de preenchimento obrigatório."
        ),
      }),
    celular: Yup.string()
      .matches(/(\(\d{2}\)\s)?(\d{4,5}-\d{4})/, "Celular inválido.")
      .required("O campo celular é de preenchimento obrigatório."),
    telefone: Yup.string().matches(
      /(\(\d{2}\)\s)?(\d{4,5}-\d{4})/,
      "Telefone inválido."
    ),
    dap: Yup.string(),
    cep: Yup.string()
      .min(9, "CEP inválido.")
      .max(9, "CEP inválido.")
      .matches(/^\d{5}-\d{3}$/, "CEP inválido.")
      .required("O campo CEP é de preenchimento obrigatório."),
    logradouro: Yup.string(),
    complemento: Yup.string(),
    bairro: Yup.string(),
    cidade: Yup.string().required(
      "O campo cidade é de preenchimento obrigatório."
    ),
    estado: Yup.string()
      .max(2, "Estado inválido.")
      .required("O campo estado é de preenchimento obrigatório."),
    banco: Yup.string(),
    area_produzida: Yup.number(), // .required("O campo área produzida é de preenchimento obrigatório."),
    conta_bancaria: Yup.string(),
    agencia: Yup.string(),
    operacao: Yup.string(),
    senha: Yup.string()
      .min(6, "A senha precisa conter no mínimo 6 caracteres")
      .required("O campo senha é de preenchimento obrigatório."),
    senha_confirmacao: Yup.string()
      .oneOf([Yup.ref("senha"), null], "As senhas não coincidem.")
      .required("O campo confirme a senha é de preenchimento obrigatório."),
    numero_associados: Yup.number().when("tipo", {
      is: 2,
      then: Yup.number().required(
        "O campo Número de associados é de preenchimento obrigatório."
      ),
    }),
    numero_associados_lei: Yup.number().when("tipo", {
      is: 2,
      then: Yup.number(),
    }),
    numero_associados_dap: Yup.number().when("tipo", {
      is: 2,
      then: Yup.number(),
    }),
    representante_legal: Yup.string().when("tipo", {
      is: 2,
      then: Yup.string().required(
        "O campo Nome do representante legal é de preenchimento obrigatório."
      ),
    }),
    representante_cpf: Yup.string()
      .matches(/^\d{3}.\d{3}.\d{3}-\d{2}$/, "CPF do representante inválido.")
      .when("tipo", {
        is: 2,
        then: Yup.string().required(
          "O campo CPF do representante é de preenchimento obrigatório."
        ),
      }),
    ic_priorizado: Yup.boolean(),
    associados_cpf:Yup.array(),
    associados:Yup.array(),
  });
