import React, { useEffect, useState } from "react";
import styles from "../../../../../common/Cadastro.module.scss";

import { Input } from "../../../../../components/Input";
import { Error } from "../../../../../components/Error";
import { ProdutoFormValues, Alimento } from "../../../../../assets/interfaces/Edital";
import { FormikErrors, FormikTouched } from "formik";

import { v4 as uuid } from "uuid";
import UnityDropdown from "../UnityDropdown";
import AddAlimentoButton from "../AddAlimentoButton";
import BaseDropdown from "../../../../../components/BaseDropdown";
import { getAlimentos } from '../../../../../services/alimentosService';

type Props = {
  handleChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  values: ProdutoFormValues;
  touched: FormikTouched<ProdutoFormValues>;
  errors: FormikErrors<ProdutoFormValues>;
  isEditingAlimento: boolean;
  setIsEditingAlimento: React.Dispatch<React.SetStateAction<boolean>>;
  alimentos: Alimento[];
  setAlimentos: (value: React.SetStateAction<Alimento[]>) => void;
};

export default function AlimentosForm({
  handleChange,
  setFieldValue,
  values,
  touched,
  errors,
  isEditingAlimento,
  setIsEditingAlimento,
  alimentos,
  setAlimentos,
}: Props) {

  const [alimentosOpcoes, setAlimentosOpcoes] = useState<
    {
      label: string;
      id: string;
      unidadeMedida: string,
    }[]
  >([]);


  useEffect(() => {
    async function getOpcoesProdutos() {
      const alimentos = await getAlimentos();
      if (alimentos && Array.isArray(alimentos)) {
        setAlimentosOpcoes(alimentos.map(alimento => ({
          id: alimento.id,
          label: alimento.nome, 
          unidadeMedida: alimento.unidade_medida,
        })));
      }
      
    }

    getOpcoesProdutos();
  }, []);


  function composeAlimento(): Alimento {
    return {
      id: values.alimento_requisitado_id,
      alimentosId: values.produto!.id,
      editaisId: values.id,
      nome: values.produto!.label,
      unidade: values.unidade!,
      quantidade: values.quantidade!,
      valor: values.valor_unitario!,
    };
  }

  function resetFields() {
    setFieldValue("alimento_requisitado_id", null);
    setFieldValue("produto", { label: "", id: "" });
    setFieldValue("unidade", "kg");
    setFieldValue("quantidade", 0);
    setFieldValue("valor_unitario", 0);
    setFieldValue("valor_total", 0);
  }

  function wasRemovedDuringUpdate(editedAlimento: Alimento) {
    return !alimentos.some((alimento) => alimento.id === editedAlimento.id);
  }

  function handleUpdateAlimento() {
    const editedAlimento = composeAlimento();
    console.log(editedAlimento)

    // alterar aqui para corrigir bug de editar edital
    let mappedAlimentos = alimentos.map((a: Alimento) =>
      a.id === values.alimento_requisitado_id ? editedAlimento : a
    );

    if (wasRemovedDuringUpdate(editedAlimento)) {
      mappedAlimentos = [editedAlimento, ...mappedAlimentos];
    }

    setAlimentos([...mappedAlimentos]);
    setIsEditingAlimento(false);
    resetFields();
  }

  function handleAddAlimento() {
    let newAlimento = composeAlimento();
    newAlimento.id = String(Math.floor(Math.random() * 1000000));

    setAlimentos([...alimentos, newAlimento]);

    resetFields();
  }

  return (
    <>
      <h3>Alimentos</h3>

      <div className={styles.formGroup}>
        <BaseDropdown
          label="Selecione o Produto"
          touched={touched}
          errors={errors}
          options={alimentosOpcoes}
          value={values.produto}
          changeCallback={(event: any) => {
            setFieldValue("produto", event.target.value);
          }}
        ></BaseDropdown>

        <UnityDropdown touched={touched} errors={errors} unidadeMedida={values?.produto?.unidadeMedida}></UnityDropdown>

        <Input
          isRequired
          label="Quantidade"
          min={0}
          name="quantidade"
          onChange={(event: any) => {
            handleChange(event);
            setFieldValue(
              "valor_total",
              values.valor_unitario
                ? event.target.value * values.valor_unitario
                : 0
            );
          }}
          type="number"
          value={values.quantidade}
        >
          {errors.quantidade && touched.quantidade ? (
            <Error text={errors.quantidade} />
          ) : null}
        </Input>

        <Input
          isRequired
          label="Valor unitário"
          min={0}
          name="valor_unitario"
          onChange={(event: any) => {
            handleChange(event);
            setFieldValue(
              "valor_total",
              values.quantidade ? event.target.value * values.quantidade : 0
            );
          }}
          step={0.01}
          type="number"
          value={values.valor_unitario}
        >
          {errors.valor_unitario && touched.valor_unitario ? (
            <Error text={errors.valor_unitario} />
          ) : null}
        </Input>

        <Input
          disabled
          isRequired
          label="Valor total"
          min={0}
          name="valor_total"
          onChange={handleChange}
          step={0.01}
          type="number"
          value={values.valor_total}
        >
          {errors.valor_total && touched.valor_total ? (
            <Error text={errors.valor_total} />
          ) : null}
        </Input>

        <AddAlimentoButton
          isEditingAlimento={isEditingAlimento}
          updateCallBack={() => handleUpdateAlimento()}
          addCallback={() => handleAddAlimento()}
        ></AddAlimentoButton>
      </div>
    </>
  );
}
