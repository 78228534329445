import { FocusEvent, useContext, useEffect, useState } from "react";
import { Formik, Form, FormikHelpers, useFormikContext } from "formik";
import { Link, useParams } from "react-router-dom";

import { initialValues } from "./initialValues";
import { validationSchema } from "./validationSchema";

import { Error } from "../../../components/Error";
import { Header } from "../../../components/Header";
import { Input } from "../../../components/Input";

import {
  Escola,
  EscolaCadastroFormValues,
} from "../../../assets/interfaces/Escola";

import { EscolaContext } from "../../../contexts/EscolaContext";

import { viacep } from "../../../services/api";
import { getEscolaById } from "../../../services/escolaService";
import styles from "../../../common/Cadastro.module.scss";

interface EscolaFormProps {
  isEditingFn: (state: boolean) => void; // Função que aceita um booleano e não retorna nada (void)
}

export default function EscolaForm({isEditingFn} : EscolaFormProps) {

  const { cadastrarEscola } = useContext(EscolaContext);
  const { values, errors, touched, handleChange, setFieldValue } = useFormikContext<EscolaCadastroFormValues>();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  let { slug }: any = useParams();

  const popularFormulario = (formulario: any, pai: string) => {
    if (formulario == null || (formulario && typeof formulario !== "object")) return ;
    const camposFormulario = Object.keys(formulario);
    for (const campo of camposFormulario) {
      if (campo === "senha") continue;
      if (formulario[`${campo}`] != null && typeof formulario[`${campo}`] !== "object") {
        if (pai === "endereco" && campo === "id") {
          setFieldValue("id_endereco", formulario[`${campo}`])
        } else {
          setFieldValue(campo, formulario[`${campo}`])
        }
      } else if (formulario[`${campo}`] != null && typeof formulario[`${campo}`] === "object") {
        popularFormulario(formulario[`${campo}`], campo)
      }
    }

    return ;
  }

  const atualizaEstadoEdicao = (flag: boolean) => {
    isEditingFn(flag)
    setIsEditing(flag)
  }

  useEffect(() => {

    async function obterDadosEscola(id: string) {
      const escola = await getEscolaById(id);
      if (escola) {
        popularFormulario(escola, '')
      }
      
    }

    if (slug) {
      const idEscola = slug;
      atualizaEstadoEdicao(true)
      obterDadosEscola(idEscola);
    } else {
      atualizaEstadoEdicao(false)
    }
  }, [slug])
  
  async function handleCEP(
    event: FocusEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) {
    const { value } = event.target;

    const cep = value.replace(/[^0-9]/g, "");
    if (cep.length !== 8) {
      return;
    }

    const { data } = await viacep.get(`${cep}/json`);

    setFieldValue("cidade", data.localidade);
    setFieldValue("logradouro", data.logradouro);
    setFieldValue("estado", data.uf);
    setFieldValue("bairro", data.bairro);
  }

  return (
    <>
    <Form>
      <section>
        <h3>Informações gerais</h3>

        <div className={styles.radioGroup}>
          <label className="custom-input-container">
            <span>Órgão Municipal</span>

            <input
              type="radio"
              name="tipo"
              value={1}
              onChange={handleChange}
              checked={Number(values.tipo) === 1}
            />

            <div className="custom-input"></div>
          </label>

          <label className="custom-input-container">
            <span>Escola Estadual</span>

            <input
              type="radio"
              name="tipo"
              value={2}
              onChange={handleChange}
              checked={Number(values.tipo) === 2}
            />

            <div className="custom-input"></div>
          </label>

          <label className="custom-input-container">
            <span>Escola Federal</span>

            <input
              type="radio"
              name="tipo"
              value={3}
              onChange={handleChange}
              checked={Number(values.tipo) === 3}
            />

            <div className="custom-input"></div>
          </label>
        </div>

        {errors.tipo && touched.tipo ? (
          <Error text={errors.tipo} />
        ) : null}

        <div className={styles.formGroup}>
          <Input
            isRequired
            label="Nome fantasia"
            name="nome_fantasia"
            onChange={handleChange}
            type="text"
            value={values.nome_fantasia}
          >
            {errors.nome_fantasia && touched.nome_fantasia ? (
              <Error text={errors.nome_fantasia} />
            ) : null}
          </Input>

          <Input
            isRequired
            label="Razão social"
            name="razao_social"
            onChange={handleChange}
            type="text"
            value={values.razao_social}
          >
            {errors.razao_social && touched.razao_social ? (
              <Error text={errors.razao_social} />
            ) : null}
          </Input>

          <Input
            isRequired
            label="CNPJ"
            mask="99.999.999/9999-99"
            name="cnpj"
            onChange={handleChange}
            type="text"
            value={values.cnpj}
          >
            {errors.cnpj && touched.cnpj ? (
              <Error text={errors.cnpj} />
            ) : null}
          </Input>

          <Input
            isRequired
            label="Telefone"
            mask="(99) 9999-9999"
            name="telefone"
            onChange={handleChange}
            type="text"
            value={values.telefone}
          >
            {errors.telefone && touched.telefone ? (
              <Error text={errors.telefone} />
            ) : null}
          </Input>

          <Input
            isRequired
            label="Nome do representante"
            name="nome_representante"
            onChange={handleChange}
            type="text"
            value={values.nome_representante}
          >
            {errors.nome_representante &&
            touched.nome_representante ? (
              <Error text={errors.nome_representante} />
            ) : null}
          </Input>

          <Input
            isRequired
            label="E-mail do representante"
            name="email_representante"
            onChange={handleChange}
            type="text"
            value={values.email_representante}
          >
            {errors.email_representante &&
            touched.email_representante ? (
              <Error text={errors.email_representante} />
            ) : null}
          </Input>

          <Input
            isRequired
            label="CPF do representante"
            mask="999.999.999-99"
            name="cpf_representante"
            onChange={handleChange}
            type="text"
            value={values.cpf_representante}
          >
            {errors.cpf_representante && touched.cpf_representante ? (
              <Error text={errors.cpf_representante} />
            ) : null}
          </Input>
        </div>
      </section>

      <section>
        <h3>Endereço</h3>

        <div className={styles.formGroup}>
          <Input
            isRequired
            label="CEP"
            mask="99999-999"
            name="cep"
            onBlur={(event) => {
              handleCEP(event, setFieldValue);
            }}
            onChange={handleChange}
            type="text"
            value={values.cep}
          >
            {errors.cep && touched.cep ? (
              <Error text={errors.cep} />
            ) : null}
          </Input>

          <Input
            label="Logradouro"
            name="logradouro"
            onChange={handleChange}
            type="text"
            value={values.logradouro}
          >
            {errors.logradouro && touched.logradouro ? (
              <Error text={errors.logradouro} />
            ) : null}
          </Input>

          <Input
            label="Complemento"
            name="complemento"
            onChange={handleChange}
            type="text"
            value={values.complemento}
          >
            {errors.complemento && touched.complemento ? (
              <Error text={errors.complemento} />
            ) : null}
          </Input>

          <Input
            label="Bairro"
            name="bairro"
            onChange={handleChange}
            type="text"
            value={values.bairro}
          >
            {errors.bairro && touched.bairro ? (
              <Error text={errors.bairro} />
            ) : null}
          </Input>

          <Input
            isRequired
            label="Cidade"
            name="cidade"
            onChange={handleChange}
            type="text"
            value={values.cidade}
          >
            {errors.cidade && touched.cidade ? (
              <Error text={errors.cidade} />
            ) : null}
          </Input>

          <Input
            isRequired
            label="Estado"
            mask="aa"
            name="estado"
            onChange={handleChange}
            type="text"
            value={values.estado}
          >
            {errors.estado && touched.estado ? (
              <Error text={errors.estado} />
            ) : null}
          </Input>
        </div>
      </section>

      <section>
        <h3>Senha</h3>

        <div className={styles.formGroup}>
          <Input
            isRequired
            label="Senha"
            name="senha"
            onChange={handleChange}
            type="password"
            value={values.senha}
          >
            {errors.senha && touched.senha ? (
              <Error text={errors.senha} />
            ) : null}
          </Input>

          <Input
            isRequired
            label="Confirme a senha"
            name="senha_confirmacao"
            onChange={handleChange}
            type="password"
            value={values.senha_confirmacao}
          >
            {errors.senha_confirmacao && touched.senha_confirmacao ? (
              <Error text={errors.senha_confirmacao} />
            ) : null}
          </Input>
        </div>
      </section>

      <section>
        <div className={styles.formGroup}>
          <span></span>
          <button type="submit">
            {isEditing ? "Atualizar Cadastro" : "Cadastrar"}          
          </button>
        </div>
      </section>
    </Form>
    </>
  );
}
