import { server } from "./api";

async function getEscolaById(id: string) {
  try {
    const { data } = await server.get(`/entidades/${id}`);

    console.log(data)
    return data;
  } catch (error: any) {
    const erro = error.response.data.error;
    // throw new Error(erro)
  }
}


async function updateEscola(escola: any) {
  try {
    const { data } = await server.put(`/entidades`, {...escola});

    console.log(data)
    return data;
  } catch (error: any) {
    const erro = error.response.data.error;
    // throw new Error(erro)
  }
}



export { getEscolaById, updateEscola };