import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Calendar,
  ChevronDown,
  DollarSign,
  Edit3,
  Trash,
  Link as LinkIcon,
} from "react-feather";
import Collapsible from "react-collapsible";
import moment from "moment";
import Swal from "sweetalert2";

import { Edital } from "../../../assets/interfaces/Edital";

import { Header } from "../../../components/Header";
import { Loader } from "../../../components/Loader";
import { Status } from "../../../components/Status";

import { server } from "../../../services/api";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import history from "../../../routes/history";

import { EscolaContext } from "../../../contexts/EscolaContext";

import emptyImage from "../../../assets/images/empty.svg";
import "./styles.scss";
import { AuthContext } from "../../../contexts/AuthContext";

export default function Editais() {
  const [editais, setEditais] = useState<Edital[]>([]);

  const { alterarStatusEdital, removerEdital } = useContext(EscolaContext);
  const { usuario } = useContext(AuthContext);

  const collapsibleEditalProps = {
    classParentString: "edital",
    contentContainerTagName: "div",
    triggerTagName: "div",
    className: "table-body",
    openedClassName: "table-body",
    triggerClassName: "table-row",
    triggerOpenedClassName: "table-row",
  };

  

  useEffect(() => {
    const getEditais = async () => {
      const { data } = await trackPromise(
        server.get("/editais", { params: { entidadeId: usuario!.id } }),
        "editais"
      );

      if (data.results) {
        setEditais(data.results);
      }
    };

    getEditais();
  }, []);

  const editaisPromise = usePromiseTracker({ area: "editais" });

  return (
    <>
      <Header
        titulo="Editais"
        descricao="Uma visão geral dos editais cadastrados por sua escola.
                  Encontre informações acerca de todos os editais disponíveis, seus status, etc."
      />

      <main id="editais">
        <div className="editais-card">
          <Loader area="editais" />

          {editais.length > 0 && editais && (
            <div className="table editais">
              <div className="table-head">
                <div className="table-row">
                  <div className="table-headers">Processo</div>

                  <div className="table-headers sm-display">
                    Chamada Pública
                  </div>

                  <div className="table-headers sm-display">Nome do Edital</div>

                  <div className="table-headers sm-display">Data Inicial</div>

                  <div className="table-headers sm-display">Data Final</div>

                  <div className="table-headers">Status</div>

                  <div className="table-headers" aria-hidden="true"></div>
                </div>
              </div>

              {editais &&
                editais.map((edital) => {
                  return (
                    <Collapsible
                      {...collapsibleEditalProps}
                      key={edital.id}
                      trigger={
                        <>
                          <div className="table-column">
                            {edital.numero_processo}
                          </div>

                          <div className="table-column sm-display">
                            {edital.chamada_publica}
                          </div>

                          <div className="table-column sm-display">
                            {edital.nome}
                          </div>

                          <div className="table-column sm-display">
                            {moment(edital.data_inicio).format("DD/MM/YYYY")}
                          </div>

                          <div className="table-column sm-display">
                            {moment(edital.data_final).format("DD/MM/YYYY")}
                          </div>

                          <div className="table-column">
                            <Status status={edital.status || 0} />
                          </div>

                          <div className="table-column">
                            <button type="button">
                              <ChevronDown color="#333333" size="1rem" />
                            </button>
                          </div>
                        </>
                      }
                    >
                      <section className="edital-card">
                        <div className="edital-card-header">
                          <div>
                            <h2>
                              {edital.nome}{" "}
                              <Status status={edital.status || 0} />
                            </h2>

                            <div>
                              <button
                                type="button"
                                className="icon editar-edital"
                                onClick={() => {
                                  history.push(`/escola/edital/${edital.id}`);
                                }}
                              >
                                <Edit3 color="#777777" size="1rem" />
                              </button>

                              <button
                                type="button"
                                className="icon remover-edital"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Excluir edital?",
                                    text: "Essa ação não pode ser desfeita.",
                                    confirmButtonText: "Excluir edital",
                                    cancelButtonText: "Cancelar",
                                    showConfirmButton: true,
                                    showCancelButton: true,
                                    showDenyButton: false,
                                    showCloseButton: false,
                                    allowOutsideClick: true,
                                    allowEnterKey: false,
                                    allowEscapeKey: true,
                                    backdrop: true,
                                    customClass: {
                                      confirmButton: "swal-confirm-button",
                                      cancelButton: "swal-cancel-button",
                                    },
                                    showClass: {
                                      popup:
                                        "animate__animated animate__slideInDown animate__faster",
                                    },
                                    hideClass: {
                                      popup:
                                        "animate__animated animate__fadeOutUp animate__faster",
                                    },
                                  }).then((result: any) => {
                                    if (result.isConfirmed) {
                                      removerEdital(edital.id || "");

                                      const filteredEditais = editais.filter(
                                        (editais) => editais.id !== edital.id
                                      );

                                      setEditais(filteredEditais);
                                    }
                                  });
                                }}
                              >
                                <Trash color="#ff6b6b" size="1rem" />
                              </button>

                              {edital.status !== 2 && (
                                <button
                                  type="button"
                                  className="suspender-edital"
                                  onClick={() => {
                                    Swal.fire({
                                      title: "Suspender edital?",
                                      text: "Você pode ativá-lo novamente quando desejar.",
                                      confirmButtonText: "Suspender edital",
                                      cancelButtonText: "Cancelar",
                                      showConfirmButton: true,
                                      showCancelButton: true,
                                      showDenyButton: false,
                                      showCloseButton: false,
                                      allowOutsideClick: true,
                                      allowEnterKey: false,
                                      allowEscapeKey: true,
                                      backdrop: true,
                                      customClass: {
                                        confirmButton: "swal-confirm-button",
                                        cancelButton: "swal-cancel-button",
                                      },
                                      showClass: {
                                        popup:
                                          "animate__animated animate__slideInDown animate__faster",
                                      },
                                      hideClass: {
                                        popup:
                                          "animate__animated animate__fadeOutUp animate__faster",
                                      },
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        alterarStatusEdital(edital, 2);
                                      }
                                    });
                                  }}
                                >
                                  Suspender edital
                                </button>
                              )}

                              {edital.status === 2 && (
                                <button
                                  type="button"
                                  className="ativar-edital"
                                  onClick={() => {
                                    Swal.fire({
                                      title: "Ativar edital?",
                                      text: "Você pode suspendê-lo novamente quando desejar.",
                                      confirmButtonText: "Ativar edital",
                                      cancelButtonText: "Cancelar",
                                      showConfirmButton: true,
                                      showCancelButton: true,
                                      showDenyButton: false,
                                      showCloseButton: false,
                                      allowOutsideClick: true,
                                      allowEnterKey: false,
                                      allowEscapeKey: true,
                                      backdrop: true,
                                      customClass: {
                                        cancelButton: "swal-cancel-button",
                                      },
                                      showClass: {
                                        popup:
                                          "animate__animated animate__slideInDown animate__faster",
                                      },
                                      hideClass: {
                                        popup:
                                          "animate__animated animate__fadeOutUp animate__faster",
                                      },
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        alterarStatusEdital(edital, 1);
                                      }
                                    });
                                  }}
                                >
                                  Ativar edital
                                </button>
                              )}
                            </div>
                          </div>

                          <h3>Chamada Pública Nº {edital.chamada_publica}</h3>

                          <h4>Processo nº {edital.numero_processo}</h4>
                        </div>

                        <div className="edital-card-body">
                          <div>
                            <h6>Data inicial</h6>

                            <p>
                              <Calendar color="#333333" size="1rem" />
                              {moment(edital.data_inicio).format("DD/MM/YYYY")}
                            </p>
                          </div>

                          <div>
                            <h6>Data de inicio da inscrição</h6>

                            <p>
                              <Calendar color="#333333" size="1rem" />
                              {moment(edital.data_inicio_inscricao).format("DD/MM/YYYY")}
                            </p>
                          </div>

                          <div>
                            <h6>Data final para inscrição</h6>

                            <p>
                              <Calendar color="#333333" size="1rem" />
                              {moment(edital.data_fim_inscricao).format("DD/MM/YYYY")}
                            </p>
                          </div>

                          <div>
                            <h6>Data de seleção dos projetos de venda</h6>

                            <p>
                              <Calendar color="#333333" size="1rem" />
                              {moment(edital.data_selecao).format("DD/MM/YYYY")}
                            </p>
                          </div>

                          <div>
                            <h6>Data final</h6>

                            <p>
                              <Calendar color="#333333" size="1rem" />
                              {moment(edital.data_final).format("DD/MM/YYYY")}
                            </p>
                          </div>

                          {!!edital.url_arquivo && (
                            <div>
                              <h6>URL do edital</h6>

                              <p>
                                <LinkIcon color="#333333" size="1rem" />{" "}
                                <a href={edital.url_arquivo.startsWith('http') ? edital.url_arquivo : "https://"+edital.url_arquivo} target="_blank">
                                  {edital.url_arquivo}
                                </a>
                              </p>
                            </div>
                          )}

                          <div>
                            <h6>
                              Total de todos os alimentos a serem adquiridos
                            </h6>

                            <p>
                              <DollarSign color="#333333" size="1rem" />
                              {new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }).format(
                                Number(
                                  Array.isArray(edital.alimentos) && edital.alimentos.length ?
                                    edital.alimentos.reduce((acc, currentElement) => {
                                      acc += (currentElement.quantidade*currentElement.valor)
                                      return acc
                                    }, 0) : 0
                                )
                              )}
                            </p>
                          </div>

                          <div>
                            <h6>Quantitativo de gêneros alimentícios</h6>
                            {edital.alimentos && edital.alimentos.length ? (
                              <table>
                                <thead>
                                  <tr>
                                    <th scope="col">Produto</th>
                                    <th scope="col">Unidade</th>
                                    <th scope="col">Quantidade</th>
                                    <th scope="col">Valor unitário</th>
                                    <th scope="col">Valor total</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {edital.alimentos.map((alimento) => {
                                    return (
                                      <tr key={alimento.nome}>
                                        <td data-label="Produto">
                                          {alimento.nome}
                                        </td>
                                        <td data-label="Unidade">
                                          {alimento.unidade}
                                        </td>
                                        <td data-label="Quantidade">
                                          {alimento.quantidade}
                                        </td>
                                        <td data-label="Valor unitário">
                                          {new Intl.NumberFormat("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                          }).format(Number(alimento.valor))}
                                        </td>
                                        <td data-label="Valor total">
                                          {new Intl.NumberFormat("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                          }).format(
                                            Number(
                                              alimento.valor *
                                                alimento.quantidade
                                            )
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            ) : (
                              <p>
                                Não há gêneros alimentícios cadastrados neste
                                edital.
                              </p>
                            )}
                          </div>
                        </div>
                      </section>
                    </Collapsible>
                  );
                })}
            </div>
          )}

          {!editais.length && !editaisPromise.promiseInProgress && (
            <div className="nenhum-edital">
              <img src={emptyImage} alt="Nenhum edital encontrado" />

              <h1>Não encontramos nenhum edital cadastrado.</h1>

              <p>
                Que tal{" "}
                <Link to="/escola/edital">cadastrar um novo edital</Link> agora?
              </p>
            </div>
          )}
        </div>
      </main>
      <br />
    </>
  );
}
