import { useContext, useState, useEffect } from 'react';
import { Formik, Form, FormikHelpers, useFormikContext, Field } from 'formik';
import { Link, useParams } from 'react-router-dom';
import { Trash2 } from 'react-feather';

import { initialValues } from './initialValues';
import { Input } from '../../../components/Input';
import { Dropdown } from "primereact/dropdown";
import { getEstados, getMunicipiosDoEstado } from '../../../services/estadosMunicipiosService';
import { Estado } from '../../../types/estadosMunicipiosTypes';


import styles from '../../../common/Cadastro.module.scss';

const CustomAssociadosInput = ({ field, form, ...props }: any) => (
  <Input
    {...field}
    {...props}

  />
);

const formBoxStyle = {
  border: '1px solid #ccc',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  padding: '16px',
  marginBottom: '16px',
  borderRadius: '8px',
  backgroundColor: '#fff',
};

const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px 20px',
  backgroundColor: '#ff4d4d',
  color: '#fff',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  marginTop: "10px",
};

export default function AssociadoComponent({index, remove, estados} : {index: number, remove: any, estados: Estado[]}) {
  const { values, setFieldValue } = useFormikContext<any>();
  const [optionsEstados, setOptionsEstados] = useState<any>([]);
  const [optionsMunicipios, setOptionsMunicipios] = useState<any>([]);
  const [opcaoSelecionada, setOpcaoSelecionada] = useState(null);
  const [municipios, setMunicipiosDoEstado] = useState<any>([]);

  const [municipioSelecionado, setMunicipioSelecionado] = useState<string>("");
  const [estadoSelecionado, setEstadoSelecionado] = useState<string>("");

  useEffect(() => {
    const options = estados.map(estado => ({label: estado.nome, id: estado.codigo_uf}));
    setOptionsEstados([...options]);
  }, [estados]);

    
  const onChangeEstado = async (value: any) => {
    setFieldValue(`associados.${index}.estado_associado`, value);
    setEstadoSelecionado(value);

    const municipiosEstado = await getMunicipiosDoEstado(value);

    if (municipiosEstado?.municipios && Array.isArray(municipiosEstado?.municipios)) {
      setOptionsMunicipios(municipiosEstado?.municipios.map((municipio: any) => ({id: municipio.codigo_ibge, label : municipio.nome})));
    }
  
  };

  const onChangeMunicipio = async (value: any) => {
    setFieldValue(`associados.${index}.municipio_associado`, value);
    setMunicipioSelecionado(value);
  
  };

  return (
    <div style={{...formBoxStyle}}>
      <div className={styles.formGroup}>
        <div >
          <Field
            isRequired
            label={`Nome do associado ${index + 1}`}
            name={`associados.${index}.nome_associado`}
            placeholder='Insira o nome do associado'
            type="text"
            component={CustomAssociadosInput}
          />  
        </div> 
        <div >
          <Field
            isRequired
            label={`CPF do associado ${index + 1}`}
            name={`associados.${index}.cpf_associado`}
            mask='999.999.999-99'
            placeholder='Insira o CPF do associado'
            type="text"
            component={CustomAssociadosInput}
          />
        </div>  
        <div>
          <Field
            isRequired
            label={`CAF do associado ${index + 1}`}
            name={`associados.${index}.caf_associado`}
            placeholder='Insira o CAF do associado'
            type="text"
            component={CustomAssociadosInput}
          />  
        </div>  
  
        <div className="card">
          <h5>Selecione um estado</h5>
          <Dropdown 
            id="estado"
            className="customdropdown"
            filter
            options={optionsEstados}
            onChange={(e) => onChangeEstado(e.value)} 
            optionValue="id"
            placeholder="Selecione um estado"
            value={estadoSelecionado}
          />
        </div>

        <div className="card">
          <h5>Selecione um municipio</h5>
          <Dropdown 
            id="municipio"
            className="customdropdown"
            filter
            options={optionsMunicipios}
            onChange={(e) => onChangeMunicipio(e.value)} 
            optionValue="id"
            placeholder="Selecione um municipio"
            value={municipioSelecionado}
          />
        </div>

        <div style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: "10px",
            padding: "10px"
        }}>
          <div className={styles.plantedContainer}>
            <label className='planted-label'>Grupo priorizado?</label>
            <br/>
            <select
              className='planted-checkbox'
              onChange={(event) => {
                const valor = event.target.value === 'sim' ? true : event.target.value === 'nao' ? false : undefined;
                setFieldValue(`associados.${index}.associado_priorizado`, valor);
              }}
            >
              <option value="selecione">Selecione</option>
              <option value="sim">Sim</option>
              <option value="nao">Não</option>
            </select>
          </div>    
          <br/>                               
          <></>
        </div>
      </div>

      <button style={buttonStyle}
          type="button"
          onClick={() => {
            const associadoNumber = values.numero_associados ? Number(values.numero_associados) : 0;
            const newValue = associadoNumber ? associadoNumber - 1 : 1;
            setFieldValue('numero_associados', newValue);
            remove(index)
          }}
        >
          <Trash2 size={'1.125rem'} style={{margin: "2px"}}/> 
          Excluir
      </button>
    </div>

  );
}
