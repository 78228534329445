import { server } from "./api";


async function getEstados (){
  try {
    const {data} = await server.get(`/estados-municipios/estados`);
    console.log({...data})
    return data;
  } catch (error: any) {
    console.log(error);
  }
}

async function getMunicipiosDoEstado (idEstado: number){
  try {
    const {data} = await server.get(`/estados-municipios/municipios?idEstado=${idEstado}`);
    console.log({...data})
    return data;
  } catch (error: any) {
    console.log(error);
  }
}

export { getEstados, getMunicipiosDoEstado };