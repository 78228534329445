import { useContext, useEffect, useState } from "react";
import { Calendar, ChevronDown, Link as LinkIcon } from "react-feather";
import Collapsible from "react-collapsible";
import moment from "moment";

import { Header } from "../../../../components/Header";
import { Loader } from "../../../../components/Loader";
import { Status } from "../../../../components/Status";
import FilterBar, {
  FiltrosEditaisAplicadosFormValues,
} from "../../../../components/FilterBar";

import { server } from "../../../../services/api";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";

import emptyImage from "../../../../assets/images/empty.svg";
import "./styles.scss";
import { Alimento, Edital } from "../../../../assets/interfaces/Edital";
import { EscolaContext } from "../../../../contexts/EscolaContext";
import { useParams, useHistory } from "react-router-dom";
import BaseButton from "../../../../components/BaseButton";
import Swal from "sweetalert2";

interface EditalComAprovados {
  id?: string;
  edital: Edital;
  aprovados: Alimento[];
}

export default function EditaisAprovados() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterValues, setFilterValues] = useState(
    {} as FiltrosEditaisAplicadosFormValues
  );

  const [editais, setEditais] = useState<Edital[]>([]);
  const [editaisSelecionados, setEditaisSelecionados] = useState<EditalComAprovados[]>([]);
  const { alterarStatusEdital, removerCandidatoEdital, runDSS2, assinarEdital } = useContext(EscolaContext);
  const [hasRun, toggleRun] = useState(false);

  const collapsibleEditalProps = {
    classParentString: "edital",
    contentContainerTagName: "div",
    triggerTagName: "div",
    className: "table-body",
    openedClassName: "table-body",
    triggerClassName: "table-row",
    triggerOpenedClassName: "table-row",
  };
  const getEditais = async () => {
    var editais:any = await trackPromise(server.get("/agricultores/editais", {params: {status:"aprovado"}}), "editais");
    
    editais = editais.data.results.map((e:any) => {return {...e.edital, 'id_candidatos_editais':e.id}});
    
    editais = editais.map((edital: Edital) => {
      return {
        ...edital,
        status: 4
      }
    });
    setEditais(editais);
   
  };

  useEffect(() => {
    getEditais();
  }, []);

  const editaisPromise = usePromiseTracker({ area: "editais" });

  const history = useHistory();

  function handleChangeCheckbox(edital: Edital) {
    var found: boolean = false;
    editaisSelecionados.forEach((e) => {
      if(e.edital===edital) {
        found = true;
        setEditaisSelecionados(editaisSelecionados.filter((item) => item.edital !== edital));
        edital.alimentos.forEach((a) => {
          (document.getElementById(`${edital.id} ${a.nome}`) as HTMLInputElement).checked = false;
        });
      }
    });
    if(!found) {
      setEditaisSelecionados(editaisSelecionados.concat({edital:edital, aprovados:edital.alimentos}));
      edital.alimentos.forEach((a) => {
        (document.getElementById(`${edital.id} ${a.nome}`) as HTMLInputElement).checked = true;
      });
    }
    //console.log(editaisSelecionados);
  }

  async function handleRunDSS2() {
    //console.log(editaisSelecionados);
    //return;
    try {
      var editaisTemp = editaisSelecionados.map((edital) => edital.edital.id) as string[];
      var alimentosMatriz:number[][] = []
      var i = 0;
      editaisSelecionados.forEach((e) => {
        alimentosMatriz.push([]);
        e.edital.alimentos.forEach((a) => {
          let found = 0;
          e.aprovados.forEach((a2) => {
            if(a === a2) found = 1;
          });
          alimentosMatriz[i].push(found);
        });
        
      });
      var newEditais = await runDSS2(editaisTemp, alimentosMatriz);
      newEditais = newEditais.map((edital: Edital) => {
        return {
          ...edital,
          status: 7
        }
      });
      //console.log(newEditais);
      toggleRun(true);
      setEditais(newEditais);
    } catch (err) {
      console.log(err);
    }
  }

  function handleDelete() {
    //console.log(editaisSelecionados);
    try {
      editaisSelecionados.forEach((edital) => {
        removerCandidatoEdital((edital.edital.id_candidatos_editais) as string);
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function handleAssinar() {
    try {
      await editaisSelecionados.forEach((edital) => {
        assinarEdital((edital.edital.id) as string);
      });
      history.push("/agricultor/editais/contratos");
    } catch (err) {
      console.log(err);
    }
  }

  function handleApproveItem(edital: Edital, alimento: Alimento) {
    let found = false;
    let newEditais = editaisSelecionados;
    newEditais.forEach((e) => {
      if (e.edital === edital){
        found = true;
        let foundAlimento = false;
        let newAlimentos = e.aprovados;
        newAlimentos.forEach((a) => {
          if(a === alimento) {
            foundAlimento = true;
            newAlimentos = newAlimentos.filter((item) => item!== alimento);
            if(newAlimentos.length===0) {
              newEditais = newEditais.filter((item) => item.edital!== edital);
              (document.getElementById(edital.id||"") as HTMLInputElement).checked = false;
            }
          }
        });
        if(!foundAlimento) {
          newAlimentos = newAlimentos.concat(alimento);
        }
        e.aprovados = newAlimentos;
      }
    });
    if(!found) {
      setEditaisSelecionados(editaisSelecionados.concat({edital, aprovados: [alimento]}));
      (document.getElementById(edital.id||"") as HTMLInputElement).checked = true;
    } else {
      setEditaisSelecionados(newEditais);
    }
  }

  return (
    <>
      <Header
        titulo="Editais aprovados"
        descricao="Visão geral de todos os editais disponíveis para candidatura."
      />

      <main id="editais-aprovados">
        <div className="editais-aprovados-card">
          <Loader area="editais" />

          {editais.length > 0 && editais && (
            <div className="table editais-aprovados">
              {/* <FilterBar setFilterFields={setFilterValues}></FilterBar> */}
              <div className="table-head">
                <div className="table-row">

                  <div className="table-headers sm-display">Entidade</div>

                  <div className="table-headers">Município</div>

                  <div className="table-headers sm-display">Data Inicial</div>

                  <div className="table-headers sm-display">Data Final</div>

                  <div className="table-headers">Status</div>

                  <div className="table-headers" aria-hidden="true"></div>
                </div>
              </div>

              {editais &&
                editais.map((edital) => {
                  return (
                    <div className="edital-container">
                      <span className="checkbox-container">
                        <input id={edital.id} className="checkbox-input" type="checkbox" onChange={() => handleChangeCheckbox(edital)}/>
                      </span>
                      <Collapsible
                        id = {`${edital.id} collapse`}
                        {...collapsibleEditalProps}
                        key={edital.id}
                        trigger={
                          <>
                            <div className="table-column sm-display">
                              {edital.entidade.nome_fantasia}
                            </div>

                            <div className="table-column">{edital.entidade.endereco?.cidade}</div>

                            <div className="table-column sm-display">
                              {moment(edital.data_inicio).format("DD/MM/YYYY")}
                            </div>

                            <div className="table-column sm-display">
                              {moment(edital.data_final).format("DD/MM/YYYY")}
                            </div>

                            <div className="table-column">
                              <Status status={edital.status || 0} />
                            </div>

                            <div className="table-column">
                              <button type="button">
                                <ChevronDown color="#333333" size="1rem" />
                              </button>
                            </div>
                          </>
                        }
                      >
                        <section className="edital-card">
                          <div className="edital-card-header">
                            <div>
                              <h2>
                                {edital.nome}{" "}
                                <Status status={edital.status || 0} />
                              </h2>
                            </div>

                            <h3>Chamada Pública Nº {edital.chamada_publica}</h3>

                            <h4>Processo nº {edital.numero_processo}</h4>
                          </div>

                          <div className="edital-card-body">
                            <div>
                              <h6>Entidade</h6>

                              <p>{edital.entidade.nome_fantasia}</p>
                            </div>

                            <div>
                              <h6>CNPJ da Entidade</h6>

                              <p>{edital.entidade.cnpj}</p>
                            </div>

                            <div>
                              <h6>Endereço</h6>

                              <p>{edital.entidade.endereco?.logradouro} - {edital.entidade.endereco?.bairro}</p>
                            </div>

                            <div>
                              <h6>Município/UF</h6>

                              <p>
                                {edital.entidade.endereco?.cidade}/{edital.entidade.endereco?.estado}
                              </p>
                            </div>

                            <div>
                              <h6>Representante da Entidade</h6>

                              <p>{edital.entidade.nome_representante || ""}</p>
                            </div>

                            <div>
                              <h6>Email do Representante da Entidade</h6>

                              <p>{edital.entidade.email_representante || ""}</p>
                            </div>

                            <div>
                              <h6>CPF do Representante da Entidade</h6>

                              <p>{edital.entidade.cpf_representante}</p>
                            </div>

                            <div>
                              <h6>Telefone</h6>

                              <p>{edital.entidade.telefone}</p>
                            </div>

                            <div>
                              <h6>Data inicial</h6>

                              <p>
                                <Calendar color="#333333" size="1rem" />
                                {moment(edital.data_inicio).format("DD/MM/YYYY")}
                              </p>
                            </div>

                            <div>
                              <h6>Data de inicio da inscrição</h6>

                              <p>
                                <Calendar color="#333333" size="1rem" />
                                {moment(edital.data_inicio_inscricao).format("DD/MM/YYYY")}
                              </p>
                            </div>

                            <div>
                              <h6>Data final para inscrição</h6>

                              <p>
                                <Calendar color="#333333" size="1rem" />
                                {moment(edital.data_fim_inscricao).format("DD/MM/YYYY")}
                              </p>
                            </div>

                            <div>
                              <h6>Data de seleção dos projetos de venda</h6>

                              <p>
                                <Calendar color="#333333" size="1rem" />
                                {moment(edital.data_selecao).format("DD/MM/YYYY")}
                              </p>
                            </div>

                            <div>
                              <h6>Data final</h6>

                              <p>
                                <Calendar color="#333333" size="1rem" />
                                {moment(edital.data_final).format("DD/MM/YYYY")}
                              </p>
                            </div>

                            {!!edital.url_arquivo && (
                              <div>
                                <h6>URL do edital</h6>

                                <p>
                                  <LinkIcon color="#333333" size="1rem" />{" "}
                                  <a href={edital.url_arquivo.startsWith('http') ? edital.url_arquivo : "https://"+edital.url_arquivo} target="_blank">
                                        {edital.url_arquivo}
                                      </a>
                                </p>
                              </div>
                            )}

                            <div>
                              <h6>Quantitativo de gêneros alimentícios</h6>

                              

                              {edital.alimentos.length > 0 ? (
                                <table>
                                  <thead className='alimentos-thead'>
                                    <tr className='alimentos-table'>
                                      <th className="grid-col" style={{gridColumnStart:1, gridColumnEnd:3}} scope="col">Produto</th>
                                      <th className="grid-col" style={{gridColumnStart:4, gridColumnEnd:5}} scope="col">Unid.</th>
                                      <th className="grid-col" style={{gridColumnStart:5, gridColumnEnd:6}} scope="col">Qtd.</th>
                                      <th className="grid-col" style={{gridColumnStart:6, gridColumnEnd:8}} scope="col">Valor</th>
                                      <th className="grid-col" style={{gridColumnStart:8, gridColumnEnd:10}} scope="col">Valor total</th>
                                      <th className="grid-col" style={{gridColumn:10}} scope="col">Aprovado</th>
                                    </tr>
                                  </thead>

                                  <tbody className='alimentos-tbody'>
                                    {edital.alimentos.map((alimento) => {
                                      return (
                                        <tr className="grid-line" key={alimento.nome}>
                                          <td className="grid-col" style={{gridColumnStart:1, gridColumnEnd:3}}data-label="Produto">
                                            {alimento.nome}
                                          </td>
                                          <td className="grid-col" style={{gridColumnStart:4, gridColumnEnd:5}} data-label="Unidade">
                                            {alimento.unidade}
                                          </td>
                                          <td className="grid-col" style={{gridColumnStart:5, gridColumnEnd:6}} data-label="Quantidade">
                                            {alimento.quantidade}
                                          </td>
                                          <td className="grid-col" style={{gridColumnStart:6, gridColumnEnd:8}} data-label="Valor unitário">
                                            {new Intl.NumberFormat("pt-BR", {
                                              style: "currency",
                                              currency: "BRL",
                                            }).format(
                                              Number(alimento.valor)
                                            )}
                                          </td>
                                          <td className="grid-col" style={{gridColumnStart:8, gridColumnEnd:10}} data-label="Valor total">
                                            {new Intl.NumberFormat("pt-BR", {
                                              style: "currency",
                                              currency: "BRL",
                                            }).format(
                                              Number(alimento.quantidade * alimento.valor)
                                            )}
                                          </td>
                                          <td className="grid-col" style={{gridColumn:10}} data-label="Aprovado">
                                            <input id={`${edital.id} ${alimento.nome}`} type="checkbox" onChange={()=>handleApproveItem(edital, alimento)}></input>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              ) : (
                                <p>
                                  Não há gêneros alimentícios cadastrados neste
                                  edital.
                                </p>
                              )}
                            </div>
                          </div>
                        </section>
                      </Collapsible>
                    </div>
                  );
                })}
            </div>
          )}

          {!editais.length && !editaisPromise.promiseInProgress && (
            <div className="nenhum-edital">
              <img src={emptyImage} alt="Nenhum edital encontrado" />

              <h1>Não encontramos nenhum edital cadastrado.</h1>
            </div>
          )}
          {((editais.length || editaisPromise.promiseInProgress) && (!hasRun)) ?
            <div className="submit-button-container">
              <button className="delete-button" onClick={() => {
                Swal.fire({
                  title: "Excluir o edital?",
                  text: "Você será removido como participante do edital.",
                  confirmButtonText: "Excluir edital",
                  cancelButtonText: "Cancelar",
                  showConfirmButton: true,
                  showCancelButton: true,
                  showDenyButton: false,
                  showCloseButton: false,
                  allowOutsideClick: true,
                  allowEnterKey: false,
                  allowEscapeKey: true,
                  backdrop: true,
                  customClass: {
                    confirmButton: "swal-confirm-button",
                    cancelButton: "swal-cancel-button",
                  },
                  showClass: {
                    popup:
                      "animate__animated animate__slideInDown animate__faster",
                  },
                  hideClass: {
                    popup:
                      "animate__animated animate__fadeOutUp animate__faster",
                  },
                }).then((result: any)=>{
                  if(result.isConfirmed){
                    handleDelete();

                    const filteredEditais = editais.filter(
                      (editais) => editais.id !== editais.id
                    );

                    setEditais(filteredEditais)
                  }
                })
                }}>Deletar</button>
              <button className="submit-button" onClick={() => handleRunDSS2()}>Analisar</button>
            </div> : <></>}

          {((editais.length || editaisPromise.promiseInProgress) && (hasRun)) ?
            <div className="submit-buttons-row">
              <BaseButton onClick={() => handleAssinar()}>
                Assinar Editais
              </BaseButton>
              <BaseButton
                onClick={() => {
                  getEditais();
                  toggleRun(false);
                }}
              >
                Simular novamente
              </BaseButton>
            </div> : <></>}

        </div>
      </main>
      <br />
    </>
  );
}
