import { AuthContext } from '../../contexts/AuthContext';
import { Link, useLocation } from 'react-router-dom';
import { LogOut, User } from 'react-feather';
import { useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';

import { modalConfig } from '../../assets/utils/modalConfig';
import history from '../../routes/history';

import style from './Navbar.module.scss';

export default function Navbar() {
  const location = useLocation();
  const { estaAutenticado, sair, usuario } = useContext(AuthContext);
  const [isPaginaInicial, setIsPaginaInicial] = useState(true);
  const [pathFormCadastro, setPathFormCadastro] = useState("");

  useEffect(() => {
    const path = location.pathname;

    let isAgricultor = ['/agricultor'].includes(path);
    let isEscola = ['/escola'].includes(path);

    if (isAgricultor) {
      setPathFormCadastro(`/agricultor/cadastrar/${usuario?.id}`)
    }

    if (isEscola) {
      setPathFormCadastro(`/escola/cadastrar/${usuario?.id}`)
    }

    setIsPaginaInicial(isAgricultor || isEscola);
  }, [location.pathname, usuario]);

  return (
    <nav className={style.nav}>
      <Link to='/'>
        <h3>Assistente PNAE</h3>
      </Link>

      {estaAutenticado ? (
        <div className={style.headerButton}>
          <div>
            {isPaginaInicial && (
              <button
              type='button'
              onClick={() => {
                history.push(pathFormCadastro);
              }}
              >
                Meu Cadastro
              </button>
            )}
          </div>
          <div>
            <button type='button' onClick={() => sair()}>
              <LogOut color='#00652e' size='1.125rem' />
              Sair
            </button>
          </div>
        </div>
      ) : (
        <button
          type='button'
          onClick={() =>
            Swal.fire({
              title: 'Agricultor ou escola?',
              text: 'Escolha a opção em que deseja se cadastrar',
              confirmButtonText: 'Agricultor',
              denyButtonText: 'Escola',
              showCancelButton: false,
              showCloseButton: false,
              showDenyButton: true,
              ...modalConfig,
            }).then((result) => {
              if (result.isConfirmed) {
                history.push('/agricultor/cadastrar');
              } else if (result.isDenied) {
                history.push('/escola/cadastrar');
              }
            })
          }
        >
          <User color='#00652e' size='1.125rem' />
          Cadastrar
        </button>
      )}
    </nav>
  );
}
