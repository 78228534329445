import { FocusEvent, useContext, useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { Link } from "react-router-dom";

import { initialValues } from "./initialValues";
import { validationSchema } from "./validationSchema";

import { Error } from "../../../components/Error";
import { Header } from "../../../components/Header";
import { Input } from "../../../components/Input";
import EscolaForm from './escolaForm'

import {
  Escola,
  EscolaCadastroFormValues,
} from "../../../assets/interfaces/Escola";

import { EscolaContext } from "../../../contexts/EscolaContext";

import { viacep } from "../../../services/api";

import styles from "../../../common/Cadastro.module.scss";
import { updateEscola } from "../../../services/escolaService";

export default function EscolaCadastro() {
  const { cadastrarEscola } = useContext(EscolaContext);
  const [isEditing, setIsEditing] = useState(false);

  const updateEditingFlag = (state: boolean) => {
    setIsEditing(state);
  }

  async function handleSubmitForm(
    values: EscolaCadastroFormValues,
    actions: FormikHelpers<EscolaCadastroFormValues>
  ) {
    actions.validateForm();
    // actions.resetForm();

    const escola: Escola = {
      tipo: Number(values.tipo),
      nome_fantasia: values.nome_fantasia,
      razao_social: values.razao_social,
      email_representante: values.email_representante,
      nome_representante: values.nome_representante,
      cpf_representante: values.cpf_representante,
      cnpj: values.cnpj,
      cep: values.cep,
      telefone: values.telefone,
      logradouro: values.logradouro || "",
      bairro: values.bairro || "",
      complemento: values.complemento || "",
      cidade: values.cidade,
      estado: values.estado,
      senha: values.senha,
    };
    if (isEditing) {

      escola.id = values.id;
      escola.id_endereco = values.id_endereco;
      
      await updateEscola(escola)
    } else {
      await cadastrarEscola(escola);
    }
    
  }



  return (
    <>
      <Header
        titulo="Assistente PNAE"
        descricao="Faça seu cadastro para ter acesso a plataforma."
      />

      <main className={styles.cadastro}>
        <div className={styles.formCard}>
          <h2>Escola e Secretaria</h2>

          {isEditing ? (
            <></>
          ) : (
            <p>
              Já possui uma conta? <Link to="/escola/acessar">Entre agora</Link>
            </p>
          )}


          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitForm}
          >
            {({ touched, values, errors, handleChange, setFieldValue }) => (
              <EscolaForm
                isEditingFn={updateEditingFlag}
              />
            )}
          </Formik>
        </div>
      </main>
      <br />
    </>
  );
}
